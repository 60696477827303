var h = Object.defineProperty;
var u = (n, i, e) => i in n ? h(n, i, { enumerable: !0, configurable: !0, writable: !0, value: e }) : n[i] = e;
var o = (n, i, e) => u(n, typeof i != "symbol" ? i + "" : i, e);
function c(n) {
  return n === "open" ? `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.19526 5.8047L7.5286 11.138C7.78895 11.3984 8.21106 11.3984 8.4714 11.138L13.8047 5.8047C14.0651 5.54435 14.0651 5.12224 13.8047 4.86189C13.5444 4.60154 13.1223 4.60154 12.8619 4.86189L8 9.72382L3.13807 4.86189C2.87772 4.60154 2.45561 4.60154 2.19526 4.86189C1.93491 5.12224 1.93491 5.54435 2.19526 5.8047Z" fill="#1B1B1B"/>
    </svg>` : `
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6877 8.14584C11.8836 8.34168 11.8836 8.65834 11.6877 8.85209L7.68773 12.8542C7.49189 13.05 7.17523 13.05 6.98148 12.8542C6.78773 12.6583 6.78564 12.3417 6.98148 12.1479L10.6273 8.50209L6.97939 4.85418C6.78356 4.65834 6.78356 4.34168 6.97939 4.14793C7.17523 3.95418 7.49189 3.95209 7.68564 4.14793L11.6877 8.14584Z" fill="#1B1B1B"/>
    </svg>
`;
}
const m = () => new Promise((n) => {
  var r;
  const e = new URL(window.location.href).searchParams.get("_envId");
  if (typeof e == "string") {
    n(e);
    return;
  }
  (r = window.pulse) == null || r.call(window, (t) => {
    n(t.getEnvironmentId());
  });
});
function g(n) {
  return `
    <div>
      <section class="consent-top">
          <div class="consent-logo-container">
            <img alt="VG logo" width="22px" height="22px" src="https://static.privacy.schibsted.com/cmp/brands/VG.svg">
            </img><img alt="Schibsted logo" width="22px" height="22px" src="https://static.privacy.schibsted.com/cmp/schibsted_logo_primary_20231017.svg"></img>
          </div>
        </section>
      <h2>${n || "Velg hvordan du vil bruke VG"}</h2>
    </div>
  `;
}
function f() {
  return `
  <div class="consent-blue" id="info-container">
    ${g("Dette var en test")}
    <article>
      <p>Valget ditt ble ikke lagret under cookieinnstillinger, kun som et resultat i denne testen. Formålet med testen er å forstå hvordan vi kan samle personopplysninger på en måte som oppleves trygg og lett å forstå.</p>
      <p>Les mer om hvordan Schibsted Media behandler personopplysninger her:</p>
      <p><a target="blank" href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/">Personvernerklæring</a></p>
    </article></div>
  <div id="button-container">
      <a href="https://iter.ly/ope29" target="_blank" id="finished-">Gi oss tilbakemeldinger</a>  
      <button id="close-consent-dialog">Lukk vinduet</button>
    </div>
  `;
}
function k() {
  return `
    <div id="info-container">
      ${g(!1)}
      <article id="intro-message">
        <h3>Kjære leser!</h3>
        <p>Vårt mål er at VGs nyhetsdekning skal være gratis for alle. Vi ønsker også å gi deg en så god opplevelse som mulig hver gang du besøker oss. Det klarer vi bare hvis du og andre lesere deler data med oss. Vi bruker denne informasjonen til å forbedre tjenestene og annonsene i VG - som finansierer journalistikken.</p>
      </article>
      <article>
        <p>Når du samtykker, godtar du at Schibsted ASA, Schibsted Media AS (samlet referert til som "Schibsted") og våre 38 partnere kan samle og bruke persondata, slik som enhets-IDer og informasjon om hvilke sider du har besøkt på VG, for disse formålene:
        </p>
      </article>
      <article id="consent-details-container" class="consent-or-pay-details">
        <details>
            <summary class="consent-or-pay-summary"><div class="consent-or-pay-chevron">${c(!1)}</div><p>Lagre og/eller få tilgang til informasjon på en enhet</p></summary>
            <p>Informasjonskapsler, enhetsidentifikatorer eller lignende nettidentifikatorer (f.eks. påloggingsbaserte identifikatorer, tilfeldig tildelte identifikatorer, nettverksbaserte identifikatorer) sammen med annen informasjon (f.eks. nettlesertype og informasjon, språk, skjermstørrelse, støttede teknologier osv.), kan lagres eller leses på enheten din for å gjenkjenne den hver gang den kobles til en app eller til et nettsted, for ett eller flere av formålene som presenteres her.</p>
        </details>
        <details>
            <summary class="consent-or-pay-summary"><div class="consent-or-pay-chevron">${c(!1)}</div><p>Personlig tilpasset annonsering og innhold, annonsering- og innholdsmåling, publikumsundersøkelser og tjenesteutvikling</p></summary>
            <p>Annonser og innhold kan personlig tilpasses basert på profilen din. Aktiviteten din på denne tjenesten kan brukes til å bygge eller forbedre en profil om deg for personlig tilpasset annonsering og innhold. Annonse- og innholdsytelse kan måles. Rapporter kan genereres basert på din og andres aktivitet. Aktiviteten din på denne tjenesten kan bidra til å utvikle og forbedre produkter og tjenester.</p>
        </details>
        <details>
            <summary class="consent-or-pay-summary"><div class="consent-or-pay-chevron">${c(!1)}</div><p>Schibsteds personlig tilpassede annonsering</p></summary>
            <p>Vi behandler data for vår egen annonseaktivitet, inkludert målgruppemålretting og segmentering.</p>
        </details>
      </article>
      <article>
        <p>Velg “Tilpass” for å justere valg knyttet til bruk av data. Du kan når som helst endre innstillingene dine via lenken “Samtykkeinnstillinger” på tjenestens side.</p>
        <p><a 
          target="blank" 
          data-track-id="consent-or-pay-intro-privacy"
          data-track-element-type="link"
          data-track-name="consent-or-pay-privacy"
          href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"
        >Personvernerklæring</a></p>
      </article>
    </div>
    <div id="button-container">
      <button
         id="consent-button"
         data-track-id="consent-or-reject-consent"
         data-track-element-type="button"
         data-track-name="consent-or-reject"
      >Godta alle</button>
      <button 
      id="reject-button"
      data-track-id="consent-or-reject-reject"
      data-track-element-type="button"
      data-track-name="consent-or-reject"
      >Avvis alle</button>
      <p><button
        id="customize"
        data-track-id="consent-or-reject-customize"
        data-track-element-type="button"
        data-track-name="consent-or-reject"
      >For å tilpasse cookies, klikk her</button></p>
    </div>`;
}
function b() {
  return `

  /* latin-ext */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9IayojdSFOd1I.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9GayojdSFO.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9IayojdSFOd1I.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9GayojdSFO.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

  #consent-or-reject-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999999;
  }

  #consent-or-reject-wrapper {
    font-family: 'Schibsted Grotesk', sans-serif !important;
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 12px;
    align-items: center;
    z-index: 999999;
  }

  
  .consent-or-reject-container {
    --consent-blue: #061A57;
    --consent-header-color: #020B23;
    --consent-text-color: #303130;
    --consent-link-color: #061A57;


    background-color: var(--background-primary, #fff);
    z-index: 1000;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 80vh;

    p {
      color: var(--consent-text-color);
    }

    #consent-details-container {
      gap: 0px;
    }

    details {
      ::marker {
        content: url("../assets/chevron-down.svg");
        margin-right: 8px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background-color: var(--consent-blue);
      }
      summary.consent-or-pay-summary {
        align-items: center;
        display: grid;
        grid-template-columns: 16px 1fr;
        line-height: 21px;
        font-weight: 600;
        gap: var(--space-xs);
        padding: 12px 0;
      }
      > p {
          margin-left: 24px;
          padding-bottom: 16px;
        }

    }

    .consent-or-pay-details > div {
      margin-top: 8px;
    }



    #consent-top-link {
      font-size: 13px;
      color: var(--consent-blue);
      display: flex;
      align-items: center;
      width: 74px;
    }

    .consent-top {
      display: flex;
      align-items: center;
      p {
        width: 100%;
        text-align: center;
        color: var(--consent-blue);
        font-weight: 700;
      }
    }

    .consent-logo-container {
      display: flex;
      gap: 8px;
    }

    article {
      display: flex;
      flex-direction: column;
      gap: 8px;

      a {
        color: var(--consent-link-color);
        text-decoration: underline;
      }
    }
    
    h3 {
      color: var(--consent-header-color);
      font-size: 16px;
      font-weight: 700;
    }


    #intro-message {
      padding: 16px;
      border: 1px solid #264BC512;
      
      color: var(--consent-blue);
      background: #F5F7FE;

      h3 {
        color: var(--consent-blue);
      }
    }
    
    
    #info-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 32px 16px 16px;

      overflow-y: scroll;  
    }
    

      h2 {
        font-size: 23px;
        color: var(--consent-header-color);
        margin-top: 6px;
      }

      #button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: var(--space-l);
        gap: 12px;
        max-height: fit-content;
        margin: 0;
        box-shadow: 0px -3px 6px 0px #00000026;

        p {
          color: var(--consent-blue);
          font-size: 12,6px;
          line-height: 19px;
          margin-bottom: 10px;
          text-align: center;

          button, a {
            text-decoration: underline;
            color: var(--consent-header-color);
            font-weight: 500;
          }
        }
      }

      #button-container > button, #button-container > a {
        background-color: var(--consent-blue);
        height: 48px;
        border-radius: 24px;
        font-size: 16px;
        color: #F5F7FE;
        display: grid;
        place-items: center;
        font-weight: 700;
      }

      #button-container:has(a) > button, #button-container:has(a) > a {
        background-color: transparent;
        border: 2px solid #F5F7FE;
      }

      .consent-or-reject-switch {
        border: 2px solid var(--consent-blue);
        position: relative;
        height: 32px;
        border-radius: 16px;
        width: 60px;

        span {
          position: absolute;
          width:24px;
          height: 24px;
          left: 2px;
          border-radius: 50%;
          background-color: var(--consent-blue);
          transform: translateY(-50%);
        }
      }

      .switch-container {
      display: grid;
      grid-template-columns: 1fr 60px;

      .consent-or-reject-switch[aria-checked="true"] {
        background-color: #EEFCED;
        span {
        right: 2px;
        left: unset;
        background-color: #375F2B;
        }




      }


  `;
}
class v extends HTMLElement {
  constructor() {
    super();
    o(this, "backdrop", document.createElement("div"));
    o(this, "container", document.createElement("div"));
    o(this, "currentView", "intro");
    o(this, "debugMode", null);
    // User buckets to show the consent or reject dialog to
    o(this, "elegibleUserBuckets", [51, 52, 53]);
    o(this, "envId", "");
    o(this, "normalTCF", null);
    o(this, "segment", null);
    o(this, "tracking", "");
    o(this, "wrapper", document.createElement("div"));
  }
  changeView(e) {
    this.currentView = e, this.renderTest();
  }
  getCookie(e) {
    const t = new RegExp(e + "=([^;]+)").exec(document.cookie);
    return t && t.length === 2 ? t[1] : null;
  }
  async connectedCallback() {
    await this.validateUser() && (this.initiateConsentOrReject(), this.renderTest(), this.container.style.display = "fixed", document.documentElement.style.overflow = "hidden");
  }
  disconnectedCallback() {
    this.normalTCF && (this.normalTCF.style.left = "unset");
  }
  initiateConsentOrReject() {
    let e;
    switch (this.segment) {
      case "0":
        e = "a";
        break;
      case "1-7":
        e = "b";
        break;
      case "8+":
        e = "c";
        break;
      default:
        e = "d";
        break;
    }
    const r = new URL(window.location.href);
    r.searchParams.set("utm_term", e), window.history.replaceState({}, "", r.toString()), localStorage.setItem(
      "consent-or-reject-test",
      JSON.stringify({ hasSeen: this.segment, user: this.envId })
    );
    const t = new MutationObserver((d) => {
      d.forEach((a) => {
        if (a.type === "childList") {
          const l = document.querySelector(
            '[id^="sp_message_container"]'
          );
          l && (this.normalTCF = l, this.normalTCF.style.left = "-9999px", t.disconnect());
        }
      });
    });
    t.observe(document.body, { childList: !0, subtree: !0 }), this.tracking = `<track-element data-track-id="consent-or-reject-${e}" data-track-name="consent-or-reject-${e}" data-track-element-type="widget" data-track-impression><div></div></track-element>`;
    const s = document.createElement("style");
    s.innerHTML = b(), this.container.classList.add("consent-or-reject-container"), this.backdrop.id = "consent-or-reject-backdrop", this.wrapper.id = "consent-or-reject-wrapper", this.backdrop.innerHTML = this.tracking, this.appendChild(this.backdrop), this.appendChild(this.wrapper), this.wrapper.appendChild(this.container), this.appendChild(s);
  }
  async renderTest() {
    switch (this.currentView) {
      case "intro": {
        this.container.innerHTML = k(), this.querySelectorAll("details").forEach((a) => {
          a.addEventListener("click", () => {
            if (!a.querySelector("summary"))
              return;
            const l = a.open, p = a.querySelector(".consent-or-pay-chevron");
            p && (p.innerHTML = c(l ? !1 : "open"));
          });
        });
        break;
      }
      case "finished": {
        this.container.innerHTML = f(), this.container.style.setProperty("--background-primary", "#061A57"), this.container.style.setProperty("--consent-header-color", "#F5F7FE"), this.container.style.setProperty("--consent-text-color", "#F5F7FE"), this.container.style.setProperty("--consent-link-color", "#F5F7FE"), localStorage.setItem(
          "consent-or-reject-test",
          JSON.stringify({ finished: !0 })
        );
        break;
      }
    }
    const e = this.querySelector("#consent-button"), r = this.querySelector("#reject-button"), t = this.querySelector("#customize"), s = this.querySelector("#close-consent-dialog");
    t == null || t.addEventListener("click", () => {
      this.changeView("finished");
    }), e == null || e.addEventListener("click", () => {
      this.changeView("finished");
    }), r == null || r.addEventListener("click", () => {
      this.changeView("finished");
    }), s == null || s.addEventListener("click", () => {
      this.remove(), document.documentElement.style.overflow = "auto";
    });
  }
  async validateUser() {
    if (!(document.documentElement.dataset.device === "mobile")) return;
    const r = new URLSearchParams(window.location.search);
    if (this.debugMode = r.get("ConsentRejectTestDebugMode"), !this.debugMode) {
      const s = parseInt(this.getCookie("clientBucket") || "0");
      if (!this.elegibleUserBuckets.includes(s))
        return;
    }
    const t = JSON.parse(
      localStorage.getItem("consent-or-reject-test") || "{}"
    );
    if (!t.finished) {
      if (t.hasSeen)
        return this.segment = t.hasSeen, this.envId = await m(), !0;
      if (this.segment === null)
        if (this.debugMode)
          this.envId = "a6c3f6d2-a2c1-4e11-ab4c-ba895e12fd05", this.segment = "1-7";
        else
          return;
      return this.segment !== null;
    }
  }
}
const x = () => {
  customElements.get("vg-consent-or-reject") || customElements.define("vg-consent-or-reject", v);
};
export {
  x as init
};
